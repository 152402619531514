import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_ADEPT_DATA, ADMIN_TRAINING_TARGET_CATEGORIES_CREATE } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import ViewHeader from 'Components/layout/panel/ViewHeader';
import Editor from 'Components/admin/trainingTargetCategories/Editor';

export default class AdminTrainingTargetCategoriesCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="admin-training-target-categories-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_ADEPT_DATA.path),
                        label: 'Kartoteka',
                    }, {
                        to: withVariables(ADMIN_TRAINING_TARGET_CATEGORIES_CREATE.path),
                        label: 'Nowa kategoria celu treningowego',
                    }]}
                >
                    <ViewHeader
                        title="Nowa kategoria celu treningowego"
                    />
                    <Editor
                        location={location}
                        history={history}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}